<template>
  <el-main>
    <div class="main">
      <h2 class="title">账号设置</h2>
      <el-row :gutter="20" class="main-info">
        <el-col :span="24">
          <el-row :gutter="20" class="info-item">
            <el-col :span="2"><span class="icon-qrcode1"></span></el-col>
            <el-col :span="18">
              <h3>二维码绑定</h3>
              <p>
                绑定《安全驾驶从这里开始》教材书后二维码，观看考前精讲。
              </p>
            </el-col>
            <el-col :span="4">
              <a class="btn" @click="dialogFormVisible = true">立即绑定</a>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="20" class="info-item">
            <el-col :span="2"><span class="icon-uniEA37"></span></el-col>
            <el-col :span="18">
              <h3>权限绑定</h3>
              <p>绑定驾校提供卡片中的二维码，开通课程权限！</p>
            </el-col>
            <el-col :span="4">
              <a class="btn" @click="dialogFormPermissions = true">立即绑定</a>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="20" class="info-item">
            <el-col :span="2"><span class="icon-mobile2"></span></el-col>
            <el-col :span="18">
              <h3>修改密码</h3>
              <p>设置车学堂账号的密码，保障账号安全！</p>
            </el-col>
            <el-col :span="4">
              <a class="btn" @click="dialogChangePassword = true">修改</a>
            </el-col>
          </el-row>
        </el-col>
        <!--              <el-col :span="24">-->
        <!--                <el-row :gutter="20" class="info-item">-->
        <!--                  <el-col :span="2">-->
        <!--                    <span class="icon-user-circle-o"></span>-->
        <!--                  </el-col>-->
        <!--                  <el-col :span="18">-->
        <!--                    <h3>更换头像</h3>-->
        <!--                    <p>拍照作为自己的头像！</p>-->
        <!--                  </el-col>-->
        <!--                  <el-col :span="4">-->
        <!--                    <a class="btn" @click="dialogHeadPortrait = true">修改</a>-->
        <!--                  </el-col>-->
        <!--                </el-row>-->
        <!--              </el-col>-->
        <el-col :span="24">
          <el-row :gutter="20" class="info-item">
            <el-col :span="2"><span class="icon-camera3"></span></el-col>
            <el-col :span="18">
              <h3>采集照片</h3>
              <p>
                拍摄正脸照片，作为人脸验证的照片，一旦确定不得修改，谨慎上传！
              </p>
            </el-col>
            <el-col :span="4">
              <a class="btn" @click="updateimg">修改</a>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      title="二维码绑定"
      :visible.sync="dialogFormVisible"
      width="30%"
      center
      :before-close="handleClose"
    >
      <el-form :model="form">
        <el-form-item label="二维码绑定" :label-width="formLabelWidth">
          <el-input v-model="form.ewmcode" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="ewm_bind(2)">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="权限绑定"
      :visible.sync="dialogFormPermissions"
      width="30%"
      center
      :before-close="handleClose"
    >
      <el-form :model="form">
        <el-form-item label="权限绑定" :label-width="formLabelWidth">
          <el-input v-model="form.authcode" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormPermissions = false">取 消</el-button>
        <el-button type="primary" @click="ewm_bind(1)">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="修改密码"
      :visible.sync="dialogChangePassword"
      width="30%"
      center
      :before-close="handleClose"
    >
      <el-steps :active="active" align-center finish-status="success">
        <el-step title="步骤 1"></el-step>
        <el-step title="步骤 2"></el-step>
        <el-step title="步骤 3"></el-step>
      </el-steps>
      <div class="step-item">
        <el-tabs v-model="activeName">
          <el-tab-pane name="0">
            <el-form :model="passForm">
              <el-form-item label="手机号:" :label-width="formLabelWidth">
                <el-input
                  v-model="passForm.phone"
                  autocomplete="off"
                  style="width:70%"
                ></el-input>
                <a style="margin-left:16px;cursor:pointer" @click="getcode"
                  >获取验证码</a
                >
              </el-form-item>
              <el-form-item label="验证码:" :label-width="formLabelWidth">
                <el-input v-model="passForm.code" autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane name="1">
            <el-form ref="passForm" :rules="rules" :model="passForm">
              <el-form-item
                label="原始密码:"
                :label-width="formLabelWidth"
                prop="orgpwd"
              >
                <el-input
                  v-model="passForm.orgpwd"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="新密码:"
                :label-width="formLabelWidth"
                prop="newpwd"
              >
                <el-input
                  v-model="passForm.newpwd"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="确认密码:"
                :label-width="formLabelWidth"
                prop="fixnewpwd"
              >
                <el-input
                  v-model="passForm.fixnewpwd"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane name="2">
            <div class="change-alt">
              <span class="icon-checkmark1"></span>
              <p>修改完成，请重新登录！</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-top: 12px;" @click="next">下一步</el-button>
      </div>
    </el-dialog>

    <!--    <el-dialog-->
    <!--      title="更换头像"-->
    <!--      :visible.sync="dialogHeadPortrait"-->
    <!--      width="30%"-->
    <!--      center-->
    <!--      :before-close="handleClose"-->
    <!--    >-->
    <!--      <el-row :gutter="20" class="replace-picture">-->
    <!--        <el-col :span="10">-->
    <!--          <div class="photo-box">-->
    <!--            <img src="../assets/images/user.png" alt="" />-->
    <!--          </div>-->
    <!--        </el-col>-->
    <!--        <el-col :span="3">-->
    <!--          <div class="swap-box">-->
    <!--            <span class="icon-swap"></span>-->
    <!--          </div>-->
    <!--        </el-col>-->
    <!--        <el-col :span="11">-->
    <!--          <div class="taking-pictures"></div>-->
    <!--          <el-button round class="pictures-btn">点击拍照</el-button>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--      <div slot="footer" class="dialog-footer">-->
    <!--        <el-button @click="dialogHeadPortrait = false">取 消</el-button>-->
    <!--        <el-button type="primary" @click="dialogHeadPortrait = false"-->
    <!--          >确 定</el-button-->
    <!--        >-->
    <!--      </div>-->
    <!--    </el-dialog>-->
    <el-dialog
      title="采集照片"
      :visible.sync="dialogCollectPictures"
      width="36%"
      center
      :before-close="closeVideo"
    >
      <el-row :gutter="20" class="replace-picture">
        <el-col :span="10">
          <div class="photo-box">
            <img v-if="imgpath === ''" src="../assets/images/user.png" alt="" />
            <img v-if="imgpath !== ''" :src="imgpath" alt="" />
          </div>
        </el-col>
        <el-col :span="3">
          <div class="swap-box">
            <span class="icon-swap"></span>
          </div>
        </el-col>
        <el-col :span="11">
          <div class="taking-pictures">
            <video
              v-show="camera"
              ref="video"
              id="video"
              autoplay="autoplay"
            ></video>
            <canvas
              v-show="img"
              id="canvas"
              width="217px"
              height="163px"
            ></canvas>
            <img v-show="imgs" id="imgTag" src="" alt="imgTag" />
          </div>
          <el-button round class="pictures-btn" @click="takePhoto"
            >点击拍照</el-button
          >
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitImg">确 定</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>
<script>
import { getResult, getLicenceName, setResult } from '@/utils/auth'
import { ewmbind, getcode, updatepassword, verifycode } from '@/api/user'
import { collection } from '../api/user'

export default {
  data() {
    const validatepassword = (rule, value, callback) => {
      const pwpattent = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,16}$/
      if (!pwpattent.test(value)) {
        callback(
          new Error(
            '密码必须为8~16位非空字符，并包含大小写字母、数字、特殊字符'
          )
        )
      } else {
        callback()
      }
    }
    return {
      imgpath: '',
      camera: true,
      img: false,
      imgs: false,
      image: '',
      avatar: '',
      real_name: '',
      licence_title: '',
      licence_name: '',
      jiaxiao_name: '',
      dialogFormVisible: false,
      dialogFormPermissions: false,
      dialogChangePassword: false,
      dialogHeadPortrait: false,
      dialogCollectPictures: false,
      form: {
        ewmcode: '',
        authcode: ''
      },
      passForm: {
        phone: '',
        code: '',
        orgpwd: '',
        newpwd: '',
        fixnewpwd: ''
      },
      active: 0,
      formLabelWidth: '100px',
      rules: {
        newpwd: [
          { required: true, trigger: 'blur', validator: validatepassword }
        ],
        fixnewpwd: [
          { required: true, trigger: 'blur', validator: validatepassword }
        ]
      }
    }
  },
  created() {
    this.licence_name = getLicenceName()
    this.getInfo()
  },
  methods: {
    //用户信息
    getInfo() {
      this.avatar = JSON.parse(getResult()).avatar
      this.real_name = JSON.parse(getResult()).real_name
      this.licence_title = JSON.parse(getResult()).licence_title
      this.jiaxiao_name = JSON.parse(getResult()).jiaxiao_name
    },
    //二维码绑定
    ewm_bind(type) {
      //this.form.type = type
      let params = {}
      if (type === 1) {
        params.code = this.form.authcode
        params.type = 1
      }
      if (type === 2) {
        params.code = this.form.ewmcode
        params.type = 2
      }
      ewmbind(params).then(res => {
        if (res.code === 0) {
          this.$message({
            message: '二维码绑定成功！',
            type: 'success'
          })
        } else {
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          } else {
            this.$message.error('二维码绑定失败！')
          }
        }
      })
    },
    //获取验证码
    getcode() {
      let params = {
        type: 3,
        phone: this.passForm.phone
      }
      getcode(params).then(res => {
        if (res.code === 0) {
          this.$message({
            message: '短信发送成功！',
            type: 'success'
          })
        }
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    next() {
      // this.active++
      // if (this.active > 2) {
      //   this.active = 0
      //   this.dialogChangePassword = false
      // }
      if (this.active === 0) {
        let params = {
          phone: this.passForm.phone,
          type: 3,
          code: this.passForm.code
        }
        verifycode(params).then(res => {
          if (res.code === 0) {
            this.active++
          } else {
            if (res.code === 1) {
              this.$message({
                message: '验证码错误！',
                type: 'warning'
              })
            }
          }
        })
      } else if (this.active === 1) {
        if (this.passForm.newpwd !== '' && this.passForm.fixnewpwd !== '') {
          if (this.passForm.newpwd !== this.passForm.fixnewpwd) {
            this.$message({
              message: '密码不一致！',
              type: 'warning'
            })
          } else {
            let params = {
              orgpwd: this.passForm.orgpwd,
              newpwd: this.passForm.newpwd
            }
            updatepassword(params).then(res => {
              if (res.code === 0) {
                this.active++
              } else {
                this.$message({
                  message: '密码修改失败！',
                  type: 'warning'
                })
              }
            })
          }
        }
      } else if (this.active === 2) {
        this.active = 0
        this.dialogChangePassword = false
      }
    },
    //采集照片
    updateimg() {
      this.dialogCollectPictures = true
      this.imgpath = JSON.parse(getResult()).checkphoto
      let mediaStreamTrack = null // 视频对象(全局)
      //let video
      let constraints = {
        video: { width: 210, height: 163 },
        audio: false
      }
      //获得video摄像头
      //video = document.getElementById('video')
      let promise = navigator.mediaDevices.getUserMedia(constraints)
      promise.then(mediaStream => {
        // mediaStreamTrack = typeof mediaStream.stop === 'function' ? mediaStream : mediaStream.getTracks()[1];
        mediaStreamTrack = mediaStream.getVideoTracks()
        this.$refs['video'].srcObject = mediaStream
        this.$refs['video'].play()
        // video.srcObject = mediaStream
        // video.play()
      })
    },
    //点击拍照
    takePhoto() {
      this.camera = false
      //this.img = true
      this.imgs = true
      //获得Canvas对象
      let video = document.getElementById('video')
      let canvas = document.getElementById('canvas')
      let ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, 210, 163)
      // toDataURL  ---  可传入'image/png'---默认, 'image/jpeg'
      let img = document.getElementById('canvas').toDataURL()
      this.image = img
      // 这里的img就是得到的图片
      //console.log('img-----', img)
      document.getElementById('imgTag').src = img
      //下载到本地
      // let ADOM = document.createElement('a')
      // ADOM.href = img
      // ADOM.download = new Date().getTime() + '.jpeg'
      // ADOM.click()
    },
    //取消
    cancel() {
      if (!this.$refs['video'].srcObject) {
        this.dialogCamera = false
        return
      }
      this.closeVideo()
      document.getElementById('imgTag').src = ''
      this.imgs = false
      this.img = false
      this.camera = true
    },
    //关闭摄像头
    closeVideo() {
      this.dialogCollectPictures = false
      //let stream = document.getElementById('video').srcObject
      let stream = this.$refs['video'].srcObject
      let tracks = stream.getTracks()
      tracks.forEach(function(track) {
        track.stop()
      })
      this.$refs['video'].srcObject = null
    },
    //确定
    submitImg() {
      if (this.image == '') {
        this.$message({
          message: '请先拍摄照片！',
          type: 'error'
        })
        return false
      }
      collection({ image: this.image }).then(res => {
        if (res.code === 0) {
          this.$message({
            message: '采集成功！',
            type: 'success'
          })
          let user = JSON.parse(getResult())
          user.avatar = res.result
          setResult(user)
          this.$router.go(0)
        } else {
          this.$message({
            message: '采集失败！',
            type: 'success'
          })
        }
      })
    }
  },
  computed: {
    activeName: {
      get() {
        return this.active.toString()
      },
      set(newValue) {
        return newValue
      }
    }
  },
  components: {}
}
</script>
